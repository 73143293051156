import './App.css';
import List from './components/List';

function App() {
  return (
    <List></List>
  );
}

export default App;
